import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home from "../screens/Home";
import TenantIndex from "../screens/tenant/index";
import TenantCreate from "../screens/tenant/create";
import TenantUpdate from "../screens/tenant/update";
import DepartmentIndex from "../screens/department/index";
import DepartmentCreate from "../screens/department/create";
import DepartmentUpdate from "../screens/department/update";
import UserIndex from "../screens/user/index";
import UserCreate from "../screens/user/create";
import UserUpdate from "../screens/user/update";
import UserSetting from "../screens/user/setting";
import PatientCreate from "../screens/patient/create";
import PatientSearch from "../screens/patient/patient-search";
import PatientProfile from "../screens/patient/profile";
import PatientUpdate from "../screens/patient/update";
import NavRoute from "./nav-route";
import ServiceProviderIndex from "../screens/service_provider/index";
import ServiceProviderCreate from "../screens/service_provider/create";
import ServiceProviderUpdate from "../screens/service_provider/update";
import ServiceProviderProfile from "../screens/service_provider/profile";
import PatientLanguageCreate from "../screens/patient_language/create";
import PatientLanguageIndex from "../screens/patient_language/index";
import PatientLanguageUpdate from "../screens/patient_language/update";
import TreatmentPathwayUpdate from "../screens/treatment_pathway/update";
import TreatmentPathwayIndex from "../screens/treatment_pathway/index";
import TreatmentPathwayCreate from "../screens/treatment_pathway/create";
import AcademicTitleCreate from "../screens/academic_title/create";
import AcademicTitleUpdate from "../screens/academic_title/update";
import AcademicTitleIndex from "../screens/academic_title/index";
import SpecialistCreate from "../screens/specialist/create";
import SpecialistUpdate from "../screens/specialist/update";
import SpecialistIndex from "../screens/specialist/index";
import PatientIndex from "../screens/patient";
import Dashboard from "../screens/dashboard/Dashboard";
import Logfile from "../screens/logfile/index";

import TreatmentPathwayIndexV2 from "../screens/treatment_pathway_v2/index";
import ForgotPassword from "../components/forgot-password";
import ResetPassword from "../components/reset-password";
import SupportMail from "../components/support-mail";
import Todo from "../screens/dashboard/Todo";
import DashboardPatient from "../screens/dashboard/Patient";
import DashboardChart from "../screens/dashboard/Chart";
import Setting from "../screens/setting";
import ReleaseNoteIndex from "../screens/release_note";
import DashboardServiceProvider from "../screens/dashboard/ServiceProvider";
import DashboardTopServiceProvider from "../screens/dashboard/TopServiceProvider";
import DashboardAgenda from "../screens/dashboard/agenda";

import SurveyIndex from "../screens/survey/index";
import SurveyCreate from "../screens/survey/create";
import SurveyEdit from "../screens/survey/update";
import SurveyShow from "../screens/survey/show";
import SurveyDetail from "../screens/survey/detail";
import UploadedPatientIndex from "../screens/uploaded-patients";

export default (
  <Router>
    <ToastContainer />
    <Switch>
      <NavRoute path="/" exact component={Home} />
      <NavRoute path="/forgot-password" exact component={ForgotPassword} />
      <NavRoute path="/reset-password" exact component={ResetPassword} />
      <NavRoute path="/support-mail" exact component={SupportMail} />

      <NavRoute path="/settings" exact component={Setting} />
      <NavRoute path="/logs" exact component={Logfile} />
      <NavRoute path="/changelog" exact component={ReleaseNoteIndex} />

      <NavRoute path="/dashboard" exact component={Dashboard} />
      <NavRoute path="/dashboard/todo" exact component={Todo} />
      <NavRoute path="/dashboard/patient" exact component={DashboardPatient} />
      <NavRoute path="/dashboard/chart" exact component={DashboardChart} />
      <NavRoute path="/dashboard/service_provider" exact component={DashboardServiceProvider} />
      <NavRoute path="/dashboard/top_service_provider" exact component={DashboardTopServiceProvider} />
      <NavRoute path="/dashboard/agenda" exact component={DashboardAgenda} />

      <NavRoute path="/tenants" exact component={TenantIndex} />
      <NavRoute path="/tenant_create" exact component={TenantCreate} />
      <NavRoute path="/tenants/:id/edit" exact component={TenantUpdate} />

      <NavRoute path="/departments" exact component={DepartmentIndex} />
      <NavRoute path="/department_create" exact component={DepartmentCreate} />
      <NavRoute path="/departments/:id/edit" exact component={DepartmentUpdate} />

      <NavRoute path="/users" exact component={UserIndex} />
      <NavRoute path="/users/new" exact component={UserCreate} />
      <NavRoute path="/users/:id/edit" exact component={UserUpdate} />
      <NavRoute path="/user-setting" exact component={UserSetting} />

      <NavRoute path="/patient_languages" exact component={PatientLanguageIndex} />
      <NavRoute path="/patient_language_create" exact component={PatientLanguageCreate} />
      <NavRoute path="/patient_languages/:id/edit" exact component={PatientLanguageUpdate} />

      <NavRoute path="/uploaded-patients" exact component={UploadedPatientIndex} />

      <NavRoute path="/patients" exact component={PatientIndex} />
      <NavRoute path="/patient_create" exact component={PatientCreate} />
      <NavRoute path="/patients/:id/profile" exact component={PatientProfile} />
      <NavRoute path="/patients/:id/edit" exact component={PatientUpdate} />

      <NavRoute path="/service_providers" exact component={ServiceProviderIndex} />
      <NavRoute path="/service_provider_create" exact component={ServiceProviderCreate} />
      <NavRoute path="/service_providers/:id/edit" exact component={ServiceProviderUpdate} />
      <NavRoute path="/service_providers/:id/profile" exact component={ServiceProviderProfile} />

      <NavRoute path="/treatment_pathways" exact component={TreatmentPathwayIndex} />
      <NavRoute path="/treatment_pathway_create" exact component={TreatmentPathwayCreate} />
      <NavRoute path="/treatment_pathways/:id/edit" exact component={TreatmentPathwayUpdate} />

      <NavRoute path="/treatment_pathways_v2" exact component={TreatmentPathwayIndexV2} />

      <NavRoute path="/academic_titles" exact component={AcademicTitleIndex} />
      <NavRoute path="/academic_title_create" exact component={AcademicTitleCreate} />
      <NavRoute path="/academic_titles/:id/edit" exact component={AcademicTitleUpdate} />

      <NavRoute path="/specialists" exact component={SpecialistIndex} />
      <NavRoute path="/specialist_create" exact component={SpecialistCreate} />
      <NavRoute path="/specialists/:id/edit" exact component={SpecialistUpdate} />

      <NavRoute path="/surveys" exact component={SurveyIndex} />
      <NavRoute path="/survey_create" exact component={SurveyCreate} />
      <NavRoute path="/surveys/:id/detail" exact component={SurveyDetail} />
      <NavRoute path="/surveys/:id/edit" exact component={SurveyEdit} />
    </Switch>
  </Router>
);
