import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { EyeInvisibleFilled, EyeOutlined, FormOutlined, WarningOutlined } from "@ant-design/icons";
import Input from "react-phone-number-input/input";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PatientAppointmentService from "../../services/patient_appointment";
import toastMessage from "../../components/toast-message";

const TodoTable = (props) => {
  const tableColunms = [
    {
      dataField: "last_name",
      text: I18n.t("activerecord.attributes.patient.last_name"),
      sort: true,
    },
    {
      dataField: "first_name",
      text: I18n.t("activerecord.attributes.patient.first_name"),
      sort: true,
    },
    {
      dataField: "birthday",
      text: I18n.t("activerecord.attributes.patient.birthday"),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return new Date(b) - new Date(a);
        }

        return new Date(a) - new Date(b);
      },

      formatter: (cellContent, row) => {
        return moment(row.birthday).format("DD.MM.YYYY");
      },
    },
    {
      dataField: "kiss_id",
      text: I18n.t("activerecord.attributes.patient.kiss_id"),
      sort: true,
    },
    {
      dataField: "current_appointment",
      text: I18n.t("form.appointment.name"),
    },
    {
      dataField: "Edit Delete",
      isDummyField: true,
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        let href = `/patients/${row.patient_id}/profile?scrollId=${row.id}`;
        if (props.selectedFilter == "same_date_appointment") {
          href = `/patients/${row.patient_id}/profile?sameDate=true`;
        }
        return (
          <div style={{ gap: 20 }} className="text-center">
            <a target="_blank" href={href} style={{ color: "red" }}>
              <EyeOutlined />
            </a>
          </div>
        );
      },
    },
  ];

  const handleDeleteWarning = (patientId, appoinmentId) => {
    if (window.confirm("Warnung löschen?")) {
      PatientAppointmentService.removeWarning(patientId, appoinmentId).then(
        (response) => {
          if (response.data.tenants.length > 0) {
            let tenants = response.data.tenants;
            setTenants(tenants);
            prepareData(tenants);
          }
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    } else {
    }
  };

  const tablePromColunms = [
    {
      dataField: "last_name",
      text: I18n.t("activerecord.attributes.patient.last_name"),
      sort: true,
    },
    {
      dataField: "first_name",
      text: I18n.t("activerecord.attributes.patient.first_name"),
      sort: true,
    },
    {
      dataField: "birthday",
      text: I18n.t("activerecord.attributes.patient.birthday"),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return new Date(b) - new Date(a);
        }

        return new Date(a) - new Date(b);
      },

      formatter: (cellContent, row) => {
        return moment(row.birthday).format("DD.MM.YYYY");
      },
    },
    {
      dataField: "kiss_id",
      text: I18n.t("activerecord.attributes.patient.kiss_id"),
      sort: true,
    },
    {
      dataField: "current_appointment",
      text: I18n.t("form.appointment.name"),
    },
    {
      dataField: "warning",
      text: "Warning",
      formatter: (cellContent, row) => {
        return (
          <div className="text-center">
            {props.isWarning && (
              <WarningOutlined
                onClick={() => handleDeleteWarning(row.patient_id, row.appointment_id)}
                className="text-danger"
              />
            )}
          </div>
        );
      },
    },
    {
      dataField: "warning",
      text: "",
      formatter: (cellContent, row) => {
        const patientLink = `/patients/${row.patient_id}/profile?scrollId=${row.id}`;
        const surveyLink = `/surveys/${row.survey_code}`;
        return (
          <div style={{ gap: 20 }} className="d-flex align-items-center justify-content-center">
            <a target="_blank" href={patientLink}>
              <EyeOutlined />
            </a>
            <a target="_blank" href={surveyLink}>
              <FormOutlined />
            </a>
          </div>
        );
      },
    },
  ];

  const providerInfos = [
    {
      dataField: "service_provider_last_name",
      text: I18n.t("form.service_provider.name") + " " + I18n.t("activerecord.attributes.patient.last_name"),
      sort: true,
    },
    {
      dataField: "service_provider_first_name",
      text: I18n.t("form.service_provider.name") + " " + I18n.t("activerecord.attributes.patient.first_name"),
      sort: true,
    },
    {
      dataField: "service_provider_phone_number",
      text: I18n.t("form.service_provider.name") + " " + I18n.t("pdf.phone_number"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <Input
            style={{ backgroundColor: "white", border: "none" }}
            disabled
            withCountryCallingCode
            international
            onClick={() => {
              navigator.clipboard
                .writeText("+" + row.service_provider_phone_number)
                .then(() => {
                  toastMessage.success("Value copied to clipboard");
                  console.log("Value copied to clipboard");
                })
                .catch((err) => {
                  console.error("Failed to copy: ", err);
                });
            }}
            onChange={() => console.log(patient.phone_number)}
            value={"+" + row.service_provider_phone_number}
          />
        );
      },
    },
  ];

  const needProviderInfos =
    props.selectedFilter == "appointment_not_confirm" || props.selectedFilter == "appoinment_missed_result";

  const needPromInfos = props.selectedFilter == "patient_threshold";

  let columns;
  if (needProviderInfos) {
    columns = [...providerInfos, ...tableColunms];
  } else if (needPromInfos) {
    columns = [...tablePromColunms];
  } else {
    columns = [...tableColunms];
  }

  return <BootstrapTable keyField="id" data={props.data} columns={columns} />;
};

export default TodoTable;
