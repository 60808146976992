import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import PatientService from "../../services/patient";
import { Spinner } from "react-bootstrap";
import * as moment from "moment";
import { EyeOutlined } from "@ant-design/icons";
import toastMessage from "../../components/toast-message";

const UploadedPatientIndex = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setPaginations] = useState({});

  useEffect(() => {
    setLoading(true);
    PatientService.getUploadedPatients(currentPage).then(
      (response) => {
        setData(response.data.patients);
        setPaginations(response.data.meta);
        setLoading(false);
      },
      (error) => {
        toastMessage.error(error.meta.message);
        setLoading(false);
      },
    );
  }, [currentPage, props.departmentId]);

  const tableColunms = [
    {
      dataField: "last_name",
      text: I18n.t("activerecord.attributes.patient.last_name"),
      sort: true,
    },
    {
      dataField: "first_name",
      text: I18n.t("activerecord.attributes.patient.first_name"),
      sort: true,
    },
    {
      dataField: "birthday",
      text: I18n.t("activerecord.attributes.patient.birthday"),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return new Date(b) - new Date(a);
        }

        return new Date(a) - new Date(b);
      },

      formatter: (cellContent, row) => {
        return moment(row.birthday).format("DD.MM.YYYY");
      },
    },
    {
      dataField: "kiss_id",
      text: I18n.t("activerecord.attributes.patient.kiss_id"),
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created At",
      formatter: (cellContent, row) => {
        return moment(row.birthday).format("DD.MM.YYYY HH:MM");
      },
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      formatter: (cellContent, row) => {
        return moment(row.birthday).format("DD.MM.YYYY HH:MM");
      },
    },
    {
      dataField: "Edit Delete",
      isDummyField: true,
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        let href = `/patients/${row.id}/profile?scrollId=${row.id}`;
        return (
          <div style={{ gap: 20 }} className="text-center">
            <a target="_blank" href={href} style={{ color: "red" }}>
              <EyeOutlined />
            </a>
          </div>
        );
      },
    },
  ];

  return !loading ? (
    <div className="container">
      <h1 className="text-center">Uploaded Patients</h1>
      <BootstrapTable keyField="id" data={data} columns={tableColunms} />
    </div>
  ) : (
    <Spinner className="mt-5 text-center" animation="border" variant="primary" />
  );
};

export default UploadedPatientIndex;
