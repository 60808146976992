import React from "react";
import {
  LoginOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  ContainerOutlined,
  MailOutlined,
  FileSearchOutlined,
  HistoryOutlined,
  FormOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Dropdown } from "react-bootstrap";
import AuthService from "../../services/auth";
import { useHistory } from "react-router-dom";
import Clinic from "images/Clinic.png";
import Doctor from "images/Doctor.png";
import Hospital from "images/hospital.png";
import Specialist from "images/Fachbereich.png";
import Patient from "images/Patient.png";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    style={{ color: "#4B4583" }}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <SettingOutlined />
    {children}
  </a>
));

export default () => {
  let navigate = useHistory();

  return (
    <Dropdown className="dropdown-user-profile">
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu>
        {AuthService.isGeneralAdmin() && (
          <Dropdown.Item onClick={() => navigate.push({ pathname: "/tenants" })} eventKey="1">
            <span className="img-wrap col-md-2">
              <img className="setting-icon-dropdown" src={Hospital} width="25" height="25" />
            </span>
            <span className="col-md-10">{I18n.t("form.tenant.name")}</span>
          </Dropdown.Item>
        )}
        {AuthService.isGeneralAdmin() && (
          <Dropdown.Item onClick={() => navigate.push({ pathname: "/departments" })} eventKey="2">
            <span className="img-wrap col-md-2">
              <img className="setting-icon-dropdown" src={Clinic} width="25" height="25" />
            </span>
            {I18n.t("form.department.name")}
          </Dropdown.Item>
        )}
        {AuthService.isGeneralAdmin() && (
          <Dropdown.Item onClick={() => navigate.push({ pathname: "/users" })} eventKey="4">
            <span className="img-wrap col-md-2">
              <UserSwitchOutlined />
            </span>
            <span className="col-md-10">{I18n.t("activerecord.models.user.other")}</span>
          </Dropdown.Item>
        )}
        {AuthService.isGeneralAdmin() && (
          <Dropdown.Item onClick={() => navigate.push({ pathname: "/uploaded-patients" })} eventKey="4">
            <span className="img-wrap col-md-2">
              <UploadOutlined />
            </span>
            <span className="col-md-10">Uploaded Patient</span>
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => navigate.push({ pathname: "/service_providers" })} eventKey="6">
          <span className="img-wrap col-md-2">
            <LoginOutlined />
          </span>
          <span className="col-md-10">{I18n.t("activerecord.models.service_provider")}</span>
        </Dropdown.Item>
        {AuthService.isGeneralAdmin() && (
          <Dropdown.Item onClick={() => navigate.push({ pathname: "/patient_languages" })} eventKey="7">
            <span className="img-wrap col-md-2">
              <img
                className="setting-icon-dropdown"
                src="https://purecatamphetamine.github.io/country-flag-icons/3x2/CH.svg"
                width="25"
                height="25"
              />
            </span>
            <span className="col-md-10">{I18n.t("activerecord.models.patient_language")}</span>
          </Dropdown.Item>
        )}
        {AuthService.isGeneralAdmin() && (
          <Dropdown.Item onClick={() => navigate.push({ pathname: "/treatment_pathways_v2" })} eventKey="9">
            <span className="img-wrap col-md-2">
              <ContainerOutlined />
            </span>
            <span className="col-md-10">{I18n.t("activerecord.models.treatment_pathway")}</span>
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => navigate.push({ pathname: "/academic_titles" })} eventKey="10">
          <span className="img-wrap col-md-2">
            <img className="setting-icon-dropdown" src={Doctor} width="25" height="25" />
          </span>
          <span className="col-md-10">{I18n.t("activerecord.models.academic_title")}</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => navigate.push({ pathname: "/specialists" })} eventKey="11">
          <span className="img-wrap col-md-2">
            <img className="setting-icon-dropdown" src={Specialist} width="25" height="25" />
          </span>
          <span className="col-md-10">{I18n.t("activerecord.models.specialist")}</span>
        </Dropdown.Item>

        {AuthService.isGeneralAdmin() && (
          <Dropdown.Item onClick={() => navigate.push({ pathname: "/logs" })} eventKey="12">
            <span className="img-wrap col-md-2">
              <FileSearchOutlined />
            </span>
            <span className="col-md-10">Logfile</span>
          </Dropdown.Item>
        )}

        {AuthService.isGeneralAdmin() && (
          <Dropdown.Item onClick={() => navigate.push({ pathname: "/settings" })} eventKey="13">
            <span className="img-wrap col-md-2">
              <SettingOutlined />
            </span>
            <span className="col-md-10">Reminder Setting</span>
          </Dropdown.Item>
        )}

        {AuthService.isGeneralAdmin() && (
          <Dropdown.Item onClick={() => navigate.push({ pathname: "/surveys" })} eventKey="13">
            <span className="img-wrap col-md-2">
              <FormOutlined />
            </span>
            <span className="col-md-10">{I18n.t("activerecord.models.survey")}</span>
          </Dropdown.Item>
        )}

        <hr />
        <Dropdown.Item onClick={() => navigate.push({ pathname: "/support-mail" })} eventKey="13">
          <span className="col-md-2">
            <MailOutlined />
          </span>
          <span className="col-md-10">{I18n.t("mail.mail_to_support")}</span>
        </Dropdown.Item>

        <hr />
        <Dropdown.Item onClick={() => navigate.push({ pathname: "/changelog" })} eventKey="13">
          <span className="col-md-10 text-end">v1.0.0</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
