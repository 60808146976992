import httpClient from "../utils/http-common";

const API_URL = "/patients";

const index = (query, page = 1) => {
  if (query == undefined) query = "";
  return httpClient.get(`${API_URL}?query=${query}&page=${page}`);
};

const getPatientId = () => {
  return httpClient.get(`${API_URL}/generate_patient_id`);
};

const getVekaData = (number) => {
  return httpClient.get(`${API_URL}/${number}/veka_number`);
};

const create = (data) => {
  return httpClient.post(API_URL, data);
};

const show = (id, format = "") => {
  if (format) {
    return httpClient.get(`${API_URL}/${id}.${format}`);
  } else {
    return httpClient.get(`${API_URL}/${id}`);
  }
};

const getUploadedPatients = (page = 1) => {
  return httpClient.get(`${API_URL}/uploaded_patients?page=${page}`);
};

const update = (id, data) => {
  return httpClient.put(`${API_URL}/${id}`, data);
};

const destroy = (id) => {
  return httpClient.delete(`${API_URL}/${id}`);
};

const deactive = (id) => {
  return httpClient.put(`${API_URL}/${id}/deactive`);
};

const remindPatientAppointment = (id) => {
  return httpClient.post(API_URL + `/${id}/patient_remind_all_appointment`);
};

const remindPatientAppointmentAfterOp = (id) => {
  return httpClient.post(API_URL + `/${id}/patient_remind_all_appointment_after_op`);
};

const getPdf = (id) => {
  return httpClient.get(API_URL + `/${id}/pdf`);
};

const getPdfAfterOp = (id) => {
  return httpClient.get(API_URL + `/${id}/pdf_after_op`);
};

const PatientService = {
  index,
  show,
  create,
  update,
  getPatientId,
  destroy,
  deactive,
  remindPatientAppointment,
  remindPatientAppointmentAfterOp,
  getPdf,
  getPdfAfterOp,
  getVekaData,
  getUploadedPatients,
};

export default PatientService;
